import React from "react";
// import "./footer.scss";
import { Box, Container, Stack, Link } from "@mui/material";

const Footer = (props) => {
  return (
    <Box sx={styles.footer} component="footer">
      <Container>
        <Stack
          display="flex"
          gap={1.5}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            sx={{ color: "grey", opacity: 0.3 }}
            href="./files/privacy-policy.pdf"
            target="_blank"
            color="primary.main"
          >
            Privacy Policy and Statement
          </Link>
          <Link
            sx={{ color: "grey", opacity: 0.3 }}
            href="./files/tos.pdf"
            target="_blank"
            color="primary.main"
          >
            User Agreement
          </Link>
          <Link
            sx={{ color: "grey", opacity: 0.3 }}
            href="./files/taxes.pdf"
            target="_blank"
            color="primary.main"
          >
            Customers Tax Responsibilities
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;

const styles = {
  footer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    minHeight: "45px",
    backgroundColor: "#fff",
    zIndex: "101",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    overflow: "hidden",
  },
};
