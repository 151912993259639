import {
  Box,
  Container,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HomePageHeader } from './Header';
import { HomePageFooter } from './Footer';

import bgImage from '../../../media/bg-home.png';
export function HomePage() {
  const navigate = useNavigate();
  function navigateTo(url) {
    navigate(url);
  }
  return (
    <Box height={'100%'} sx={styles.wrapper}>
      <HomePageHeader />
      <Box pt={2} pb={2}>
        <Container>
          <Stack sx={styles.textBox}>
            <Typography
              fontFamily='"Monument Extended"'
              mb={3}
              fontSize={56}
              variant='h1'
              color='#fff'
              sx={{
                '@media (max-width: 720px)': {
                  fontSize: 24,
                },
              }}>
              Open source crypto wallet
            </Typography>
            <Typography
              fontFamily='helveticaneuecyr'
              mb={2}
              variant='body1'
              color='#fff'
              lineHeight={1.8}
              sx={{ opacity: 0.5 }}>
              Blockchain wallet friendly with decentralized and p2p exchanges.
              Based on absolutely secured transactions system
            </Typography>
            <Button sx={styles.button} onClick={() => navigateTo('/login')}>
              Get started
            </Button>
          </Stack>
        </Container>
      </Box>
      <HomePageFooter />
    </Box>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'linear-gradient(109.79deg, #1a1b26 9.29%, #000105 42.6%);',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: "''",
      top: 0,
      right: 0,
      background: `url(${bgImage}) no-repeat`,
      width: '1000px',
      height: '100%',
      pointerEvents: "none"
    },
  },
  textBox: {
    maxWidth: '600px',
    width: '100%',
  },
  button: {
    width: 150,
    borderRadius: 2.5,
    pt: 2,
    pb: 2,
    border: '1px solid #fff',
    background:
      'linear-gradient(246.4deg, rgba(1, 2, 6, 0) 17.21%, rgba(0, 175, 255, 0.124282) 41.57%, rgba(50, 155, 201, 0.168) 49.13%, rgba(104, 62, 48, 0.268) 68.85%, rgba(1, 2, 6, 0.2) 83.48%)',
    color: '#fff',
  },
};
