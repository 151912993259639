import { Box, Typography, Container } from '@mui/material';
export function HomePageFooter() {
  return (
    <Box borderTop='1px solid #fff' pt={3} pb={3} component={'footer'}>
      <Container>
        <Typography sx={{ opacity: 0.5 }} fontSize={12} color='#fff'>
          Copyright © 2021 Legaltransitcrypto.finance. All rights reserved. Legaltransitcrypto.finance is part
          of Bitco Income Property Fund LLC SEC CIK #0001548569
        </Typography>
      </Container>
    </Box>
  );
}
