import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";
import { changeState } from "../../redux/slices/burgerState";
import {
  Calculate,
  Dashboard,
  Article,
  SupportAgent,
  Logout,
  AccountBalance,
} from "@mui/icons-material";

import bitcoinImage from "../../media/svg/bitcoin.svg";

import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { Dropdown } from "./Dropdown";
// import "./side-bar.scss";

function SideBar(props) {
  const { user, currency } = props;
  const matches = useMediaQuery("(max-width:1024px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActiveBurger = useSelector((state) => state.burgerReducer.burgerMenu);

  function navigateTo(url) {
    if (matches) dispatch(changeState());
    navigate(url);
  }
  function logOut() {
    window.localStorage.removeItem("id");
    navigate("/");
  }

  return (
    <Box
      component="aside"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      sx={{
        ...styles.sideBar,
        left: isActiveBurger || !matches ? 0 : "-100%",
      }}
    >
      <Dropdown user={user} currency={currency} />
      <Box overflow="auto">
        {numberLists.map((list) => {
          return (
            <List key={list.heading} sx={styles.list}>
              <Typography fontSize={18} fontWeight={700} color="primary.main">
                {list.heading}
              </Typography>
              {list.children.map((child) => {
                return (
                  <ListItem key={child.href} sx={styles.item} disablePadding>
                    <ListItemButton
                      onClick={() => navigateTo(child.href)}
                      sx={styles.button}
                    >
                      <ListItemIcon>{child.icon}</ListItemIcon>
                      <Typography fontWeight={500} fontSize={20}>
                        {child.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          );
        })}
        <List sx={styles.list}>
          <ListItem disablePadding sx={styles.item}>
            <ListItemButton
              href="mailto:support@legaltransitcrypto.finance"
              sx={styles.button}
            >
              <ListItemIcon>
                <SupportAgent />
              </ListItemIcon>
              <Typography fontWeight={500} fontSize={20}>
                Support
              </Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={styles.item}>
            <ListItemButton onClick={() => logOut()} sx={styles.button}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <Typography fontWeight={500} fontSize={20}>
                Log out
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

const styles = {
  sideBar: {
    width: 250,
    position: "fixed",
    top: 40,
    bottom: 0,
    transition: "0.5s all ease",
    height: "calc(100vh - 124px)",
    // height: '100vh',
    // mb: '40px',
    // transform: 'translateY(50px)',
    backgroundColor: "common.white",
    p: 1,
    mb: "45px",
    // pt: '50px',
    // pb: '50px',
    zIndex: 100,
    overflow: "auto",
    // overflowY: 'scroll',
  },
  list: {
    gap: 2,
    display: "flex",
    flexDirection: "column",
    mb: 5,
  },
  item: {
    color: "text.primary",
    borderRadius: 2,
    transition: "all 0.4s ease",
    "&:hover": {
      backgroundColor: "rgba(77, 163, 254, 0.2)",
      color: "#007aff",
    },
  },
};

const numberLists = [
  {
    heading: "",
    children: [
      { name: "Dashboard", href: "dashboard", icon: <Dashboard /> },
      { name: "Accumulation", href: "accumulation", icon: <Calculate /> },
    ],
  },
  {
    heading: "Wallet",
    children: [
      {
        name: "Bitcoin",
        href: "bitcoin",
        icon: <img src={bitcoinImage} alt="bitcoin icon" />,
      },
    ],
  },
  {
    heading: "Settings",
    children: [
      { name: "Documents", href: "documents", icon: <Article /> },
      {
        name: "Declared cryptocurrency",
        href: "declared",
        icon: <AccountBalance />,
      },
    ],
  },
];

export default SideBar;
